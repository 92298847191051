import React from 'react';
import Header from '../Header/Header';
import './Hero.css';
import hero_image from '../../assets/hero_image.png';
import hero_image_back from '../../assets/hero_image_back.png';
import Hearts from '../../assets/heart.png';
import calories from '../../assets/calories.png';


const Hero = () => {
    return (
        <div className="hero">
            <div className="left-h">
                <Header />
                {/* the best add */}
                <div className="the-best-ad">
                    <div></div>
                    <span>The best fitness club in the town</span>
                </div>

                {/* hero heading */}
                <div className="hero-text">
                    <div>
                        <span className='stroke-text'>Shape </span>
                        <span>Your</span>
                        </div>
                        <div><span>
                            Ideal Body</span></div>
                <div><span>
                    In here we will help you to shape and build your ideal body and live up your life to fullest
                    </span>
            </div>
            </div>
            {/* figures */}
            <div className="figures">
                <div>
                    <span>+140</span>
                <span>Expert coaches</span>
                </div>
                <div>
                    <span>+978</span>
                <span>Members joined</span>
                </div>
                <div>
                    <span>+50</span>
                <span>fitness programs</span>
                </div>
            </div>
            {/* hero buttons */}
            <div className="hero-buttons">
                <buttons className="btn">Get Started</buttons>
                <buttons className="btn">Learn More</buttons>
            </div>
            </div>
            <div className="right-h">
                <button className='btn'>Join Now</button>


                <div className="heart-rate">
                    <img src={Hearts} alt="" />
                    <span>Heart Rate</span>
                    <span>116 bpm</span>
                </div>

                {/* hro images */}
                <img src={hero_image} alt="" className="hero-image" />
                <img src={hero_image_back} alt="" className="hero-image-back" />
            
            {/* calories */}
            <div className="calories">
                <img src={calories} alt="" />
                <div>
                <span>Calories Burned</span>
                <span>220 kcal</span>
                </div>
            </div>
            </div>
        </div>
    );
}

export default Hero
import image1 from "../assets/t-image1.png";
import image2 from "../assets/t-image2.jpg";
import image3 from "../assets/t-image3.jpg";

export const testimonialsData = [
  {
    image: image1,
    review:
      "I made the right choice by choosing the Fitclub and by choosing the right plan and program I already achieved my ideal body!",
    name: 'MATHEW HENDRICKSON',
    status : 'ENTREPRENEUR'
  },
  {
    image: image2,
    review: 'I joined Fitness Within because I wanted a change of pace. I was so used to the same routine at my gym, that my body was craving a switch!',
    name: 'JOHN KEVIN',
    status: 'COACH'
  },
  {
    image : image3,
    review:'The one thing I appreciate about the culture is that it’s an actively positive and friendly environment, but serious about strength training!',
    name: 'FRANKLIN',
    status: "CUSTOMER"
  }
];